import React from 'react';

import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';

import SEO from '../components/commons/Seo';
import ImageBackground from '../components/commons/ImageBackground';

import ContentItem from '../components/ContentItem';
import Footer from '../components/commons/Footer';
import Section from '../components/commons/Section';

import { useIntl } from 'react-intl';
import useScrollTo from '../hooks/useScrollTo';

import ContentImg1 from '../images/news-and-sports/content1.jpg';
import ContentImg2 from '../images/news-and-sports/content2.jpg';

import schemaImgEn from '../images/news-and-sports/schema.png';
import chartsImgEn from '../images/news-and-sports/charts.png';

import schemaImgRu from '../images/news-and-sports/schema-ru.png';
import chartsImgRu from '../images/news-and-sports/charts-ru.png';

import '../styles/news-and-sports.scss';

const NewsAndSportsContent = () => {
  const { locale, formatMessage } = useIntl();
  const [footerRef, scrollToFooter] = useScrollTo(null);

  const schemaImg = locale === 'ru' ? schemaImgRu : schemaImgEn;
  const chartsImg = locale === 'ru' ? chartsImgRu : chartsImgEn;

  return (
    <>
      <SEO title={formatMessage({ id: 'news.title' })} className="kedoo-news-and-sports" />
      <ImageBackground>
        <div className="main-section__bg" />
        <Section className="w-100">
          <Container>
            <Row>
              <Col md={8} xl={7}>
                <div className="fullsize-section__content">
                  <div className="fullsize-section__logo news-and-sports-logo mb-2" />
                  <h2
                    className="text-white"
                    dangerouslySetInnerHTML={{
                      __html: formatMessage({ id: 'news.main_section.title' })
                    }}
                  />
                  <p className="lead text-white">
                    {formatMessage({ id: 'news.main_section.content' })}
                  </p>
                  <Button
                    variant="outline-light"
                    size="lg"
                    onClick={scrollToFooter}
                    className="d-flex align-items-center"
                  >
                    <span className="text-nowrap">
                      {formatMessage({ id: 'commons.contact_us' })}
                    </span>
                    <span className="right-arrow" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </ImageBackground>
      <main className="pb-3">
        <Section big>
          <Container>
            <ContentItem orderContentFirst image={ContentImg1}>
              <h2>{formatMessage({ id: 'news.solutions_section.users.title' })}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ id: 'news.solutions_section.users.content' })
                }}
              />
            </ContentItem>
            <ContentItem image={ContentImg2}>
              <h2>{formatMessage({ id: 'news.solutions_section.technology.title' })}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ id: 'news.solutions_section.technology.content' })
                }}
              />
            </ContentItem>
          </Container>
        </Section>

        <Jumbotron className="px-0">
          <Section>
            <Container>
              <ContentItem orderContentFirst image={schemaImg} imgCenter>
                <h2>{formatMessage({ id: 'news.aggregator_section.title' })}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({ id: 'news.aggregator_section.content' })
                  }}
                />
              </ContentItem>
            </Container>
          </Section>
        </Jumbotron>

        <Section>
          <Container>
            <ContentItem image={chartsImg} className="mb-0">
              <h2>{formatMessage({ id: 'news.result_section.title' })}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ id: 'news.result_section.content' })
                }}
              />
            </ContentItem>
          </Container>
        </Section>
      </main>
      <Footer ref={footerRef} />
    </>
  );
};

export default NewsAndSportsContent;
