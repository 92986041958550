import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '../components/Layout';

import NewsAndSportsContent from '../contents/news-and-sports';

const NewsAndSportsPage: React.FC<PageProps> = (props) => {
  return (
    <Layout location={props.location}>
      <NewsAndSportsContent />
    </Layout>
  );
};

export default NewsAndSportsPage;
